import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-hot-toast';
import BusService from '../../services/BusService';


export default function DeleteConfirmationDialog({ open, bus, handleClose, notifyUpdate }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  function deleteBusById(busId) {
    BusService.deleteBus(busId)
      .then(() => { notifyUpdate(); toast.success(<text>Deleted <b>successfully!</b></text>); })
      .catch(error => toast.error(<text><b>Error {error.response.status}</b> occured.</text>));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}>
        <DialogTitle>
          You are about to delete this bus record
        </DialogTitle>
        <DialogContent>
            <Box display='flex' flexDirection='column' gap={3}>
                <DialogContentText>
                  This bus record will be deleted from database.
                  Are you sure?
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button 
                      autoFocus
                      variant='contained'
                      onClick={() => {
                          deleteBusById(bus.id);
                          handleClose(); 
                      }}>
                      Delete
                    </Button>
                </DialogActions>
            </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}