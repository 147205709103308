import { AiOutlineArrowLeft } from 'react-icons/ai'
import { AppBar, Toolbar, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';

export default function AboutNavbar() {
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <AppBar position='sticky' sx={{ background: 'transparent' }} elevation={0}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box display='flex' alignItems='center' sx={{ fontWeight: 'bold' }} gap={2}>
                    <IconButton onClick={() => { navigate('/') }}>
                        <AiOutlineArrowLeft size='36' color='#9063A7'/>
                    </IconButton>
                    <Typography
                        hidden={isSmallScreen ? true : false}
                        variant='h4'
                        fontWeight='bold'>
                        About
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    )
}