import { useEffect, useState } from 'react';
import { FaRoute } from 'react-icons/fa'
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog/DeleteConfirmationDialog';


const columns = [{
    id: 'rowNumber',
    label: '№',
    minwidth: 24,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
    }, { 
    id: 'busNumber',
    label: 'Bus number',
    minwidth: 36,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  }, { 
    id: 'onRoute',
    label: 'Status',
    minwidth: 24,
    align: 'center'
  }, { 
    id: 'route.routeNumber',
    label: 'Route number',
    minwidth: 24,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  }, { 
    id: 'driver.surname',
    label: 'Surname',
    align: 'center',
    minwidth: 96 
  }, {
    id: 'driver.initials', 
    label: 'Initials', 
    align: 'center', 
    minwidth: 48 
}];

function getValue(row, columnId) {
  if (columnId.includes('.')) {
    const [objectName, propertyName] = columnId.split('.');
    return row[objectName][propertyName];
  } else {
    return row[columnId];
  }
}

function descendingComparator(a, b, orderBy) {
  if (getValue(b, orderBy) < getValue(a, orderBy)) {
    return -1;
  }
  if (getValue(b, orderBy) > getValue(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ColumnGroupingTable({ rows, notifyCollectionUpdate }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('busNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedRows, setSortedRows] = useState(rows.sort(getComparator(order, orderBy)));
  const [newBusDialogOpen, setNewBusDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleNewBusDialogClose = () => {
    setNewBusDialogOpen(!newBusDialogOpen);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  useEffect(() => {
    setSortedRows(rows.sort(getComparator(order, orderBy)));
  }, [rows, order, orderBy]);

  return (
    <Paper sx={{
      width: '95%', 
      height: '95%', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'space-between',
      borderRadius: 6,
      p: 1.5
    }}>
      <TableContainer sx={{ height: '100%', overflow: 'auto'}}>
        <Table stickyHeader aria-label='sticky table' sx={{ flex: 1, overflow: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell align='center' colSpan={1}/>
              <TableCell align='center' colSpan={2}>
                <Typography fontWeight='700'>
                  Bus
                </Typography>
              </TableCell>
              <TableCell align='center' colSpan={1}>
                <Typography fontWeight='700'>
                  Route
                </Typography>
              </TableCell>
              <TableCell align='center' colSpan={2}>
                <Typography fontWeight='700'>
                  Driver
                </Typography>
              </TableCell>
              <TableCell align='center' colSpan={1} p={0}/>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minwidth: column.minwidth }}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={
                    column.id == 'rowNumber'
                      ? { borderRight: '1px solid rgba(224, 224, 224, 1)' }
                      : {}
                  }>
                  {column.id !== 'rowNumber' ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}>
                    <Typography>
                      {column.label}
                    </Typography>
                    {orderBy === column.id ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                  ) : (
                  <Typography>
                    {column.label}
                  </Typography>)}
                </TableCell>
              ))}
              <TableCell
                key='actions'
                align='center'
                style={{ top: 57, width: 'fit-content', padding: 0 }}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(sortedRows)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => { setSelectedRow(row)}}
                    sx={{
                      '&:hover': {
                        '& .MuiIconButton-root': {
                          visibility: 'visible',
                        },
                      },
                    }}>
                    {columns.map((column) => {
                      const value = getValue(row, column.id);
                      return (
                        <TableCell 
                          key={column.id}
                          align={column.align}
                          minwidth={column.minwidth}
                          sx={
                            column.id === 'rowNumber'
                              ? { borderRight: '1px solid rgba(224, 224, 224, 1)' }
                              : {}
                          }>
                          {column.id === 'onRoute' ? (
                            <FaRoute fontSize={20} style={{ color: value ? '#9063A7' : '#E0E0E0' }}/>
                          ) : column.id == 'rowNumber' ? (
                            index + 1
                          ) : column.format && typeof value === 'number' ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell sx={{ width: 0, pl: 0, pr: 2 }}>
                      <IconButton
                        aria-label='delete'
                        size='medium'
                        align='center'
                        onClick={() => { handleNewBusDialogClose() }}
                        sx={{ width: 'fit-content', visibility: 'hidden' }}>
                        <DeleteIcon fontSize='inherit' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ overflowY: 'hidden' }}
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={sortedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}/>
      <DeleteConfirmationDialog
        open={newBusDialogOpen} 
        bus={selectedRow} 
        handleClose={handleNewBusDialogClose} 
        notifyUpdate={notifyCollectionUpdate}/>
    </Paper>
  );
}