import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { AiFillFileAdd } from 'react-icons/ai'; 
import { FaRoute } from 'react-icons/fa';
import { RiParkingFill } from 'react-icons/ri';


export default function ActionMenu({ handleUpdateBusDialogMode, handleUpdateBusDialog, handleNewBusDialog }) {
  const actions = [{
      icon: <AiFillFileAdd />,
      name: 'Add new bus',
      onClick: () => { handleNewBusDialog() }
    }, { 
      icon: <FaRoute />,
      name: 'Record the departure of the bus',
      onClick: () => { handleUpdateBusDialog(); handleUpdateBusDialogMode('departure') } 
    }, { 
      icon: <RiParkingFill />, 
      name: 'Record the arrival of the bus', 
      onClick: () => { handleUpdateBusDialog(); handleUpdateBusDialogMode('arrival') }  
  }];
  
  return (
    <SpeedDial
      icon={<SpeedDialIcon />}
      ariaLabel='speed dial action menu'
      direction='left'>
      {actions.map((action) => (
        <SpeedDialAction
        key={action.name}
        icon={action.icon}
        tooltipTitle={action.name}
        onClick={action.onClick}/>
      ))}
    </SpeedDial>
  );
}
