import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainNavbar from '../Navbars/MainNavbar';
import Footer from '../Footer/Footer';

export function MainPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [pxForMainComponents, setPxForMainComponents] = useState(16);
    const [maxWidthForTextArea, setMaxWidthForTextArea] = useState('60%');
    

    useEffect(() => {
        if (isSmallScreen) {
            setPxForMainComponents(4)
            setMaxWidthForTextArea('100%')
        } else if (isMediumScreen) {
            setPxForMainComponents(8)
            setMaxWidthForTextArea('100%')
        } else {
            setPxForMainComponents(16)
            setMaxWidthForTextArea('60%')
        }
    }, [isSmallScreen, isMediumScreen]);

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box pt={8} pb={3} px={pxForMainComponents}>
                <MainNavbar/>
            </Box>
            <Box p={1} px={pxForMainComponents} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, overflow: 'auto' }}>
                <Box display='flex' gap={6} justifyContent='space-between' alignItems='center'>
                    <Box maxWidth={maxWidthForTextArea}>
                        <Typography py={2} variant='h6' fontWeight='bold' color='#FEBF0F'>Bus Fleet Management System</Typography>
                        <Typography variant='h3' fontWeight='bold' color='#FFFFFF'>Want to see Fleetle in action?</Typography>
                        <Typography py={1} color='#E0E0E0'>
                            Replace spreadsheets and paper with a centralized mobile bus fleet management
                            solution designed to make your work easier. Fully optimize your fleet by giving
                            fleet managers, route managers, drivers, and other personnel
                            access to the tools and information they need.
                        </Typography>
                        <Typography py={1} variant='h6' fontWeight='bold' color='#FEBF0F' sx={{ cursor: 'pointer' }} onClick={() => {navigate('/dashboard')}}>Go to dashboard →</Typography>
                    </Box>
                    <Box position='relative' maxWidth='30%' sx={{ height: '100%', width: '100%', display: { xs: 'none', md: 'flex' }, borderRadius: '10%', overflow: 'hidden' }}>
                        <img src='/driver.jpg' style={{ height: '100%', width: '100%', objectFit: 'cover' }}></img>
                        <Box sx={{ position: 'absolute', height: '50%', left: 0, right: 0, bottom: 0, background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,1))' }}/>
                    </Box>
                </Box>
            </Box>
            <Box py={4} display='flex' alignItems='center' justifyContent='center'>
                <Footer/>
            </Box>
        </Box>
    )
}