import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Toaster, toast } from 'react-hot-toast';
import DashboardNavbar from '../Navbars/DashboardNavbar';
import ColumnGroupingTable from '../Table/Table';
import Footer from '../Footer/Footer';
import BusService from '../../services/BusService';

export function DashboardPage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [pxForMainComponents, setPxForMainComponents] = useState(16);
    const [newBusDialogOpen, setNewBusDialogOpen] = useState(false);
    const [updateBusDialogOpen, setUpdateBusDialogOpen] = useState(false);
    const [updateBusDialogMode, setUpdateBusDialogMode] = useState('');
    const [collectionUpdated, setCollectionUpdated] = useState(false);
    const [buses, setBuses] = useState([]);

    useEffect(() => {
        BusService.getAllBuses()
            .then(response => setBuses(response.data))
            .catch(error => { console.log(error); toast.error(<text><b>Error {error.response.status}</b> occured.</text>) });
    }, []);

    useEffect(() => {
        if (collectionUpdated) {
            BusService.getAllBuses()
                .then(response => { setBuses(response.data); setCollectionUpdated(false); })
                .catch(error => { console.log(error); toast.error(<text><b>Error {error.response.status}</b> occured.</text>) });
        }
    }, [collectionUpdated]);

    useEffect(() => {
        if (isSmallScreen) {
            setPxForMainComponents(4)
        } else if (isMediumScreen) {
            setPxForMainComponents(8)
        } else {
            setPxForMainComponents(16)
        }
    }, [isSmallScreen, isMediumScreen]);

    const handleNewBusDialogClose = () => {
        setNewBusDialogOpen(!newBusDialogOpen);
    };

    const handleUpdateBusDialogClose = () => {
        setUpdateBusDialogOpen(!updateBusDialogOpen);
    };

    const handleUpdateBusDialogMode = (mode) => {
        setUpdateBusDialogMode(mode);
    };

    const notifyCollectionUpdate = () => {
        setCollectionUpdated(true);
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box pt={8} pb={3} px={pxForMainComponents}>
                <DashboardNavbar 
                    newBusDialogOpen={newBusDialogOpen}
                    handleNewBusDialogClose={handleNewBusDialogClose}
                    updateBusDialogOpen={updateBusDialogOpen}
                    updateBusDialogMode={updateBusDialogMode}
                    handleUpdateBusDialogClose={handleUpdateBusDialogClose}
                    handleUpdateBusDialogMode={handleUpdateBusDialogMode}
                    notifyCollectionUpdate={notifyCollectionUpdate}/>
            </Box>
            <Box p={1} px={pxForMainComponents} sx={{ display: 'flex', flex: 1, overflow: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                <ColumnGroupingTable rows={buses} notifyCollectionUpdate={notifyCollectionUpdate}/>
            </Box>
            <Box py={4} display='flex' alignItems='center' justifyContent='center'>
                <Footer/>
            </Box>
            <Toaster
                position='bottom-right'
                toastOptions={{ 
                    duration: 2800, 
                    style : {
                         padding: 16
                    } 
                }}/>
        </Box>
    )
}