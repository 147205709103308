import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DashboardPage } from './components/Pages/DashboardPage';
import { AboutPage } from './components/Pages/AboutPage';
import { MainPage } from './components/Pages/MainPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';


const theme = createTheme({
  typography: {
    fontFamily: 'Roboto Condensed',
    caption: {
      color: 'gray'
    }
  },
  palette: {
    primary: {
      light: '#E1E289',
      main: '#FEBF0F',
      dark: '#CB980B',
      contrastText: '#FFF'
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<MainPage/>} />
          <Route path='/dashboard' element={<DashboardPage/>} />
          <Route path='/about' element={<AboutPage/>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;