import axios from 'axios';

const BASE_URL =  process.env.REACT_APP_BUSES_REST_API_URL;

class BusService {
  getAllBuses() {
    return axios.get(BASE_URL);
  }

  getBusByBusNumber(busNumber) {
    return axios.get(`${BASE_URL}/number/${busNumber}`);
  }

  createBus(bus) {
    return axios.post(BASE_URL, bus);
  }

  updateBus(id, bus) {
    return axios.put(`${BASE_URL}/${id}`, bus);
  }
  
  deleteBus(id) {
    return axios.delete(`${BASE_URL}/${id}`);
  }
}

export default new BusService();
