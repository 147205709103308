import { Box, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import BusService from '../../services/BusService';


const validationSchema = Yup.object().shape({
  busNumber: Yup.string()
    .required('Bus Number is required')
    .test(
      'check-for-existence',
      'There is no bus with such a number',
      async function(value) {
        try {
          await BusService.getBusByBusNumber(value);
          return true;
        } catch (error) {
          if (error.response.status === 404) {
            return false;
          }
        }
      }
    )
});

export default function UpdateBusStatusFormDialog({ open, mode, handleClose, notifyUpdate }) {
  function updateBusStatus(busNumber, onRoute) {
      return BusService.getBusByBusNumber(busNumber)
      .then(response => {
        const busId = response.data.id;
        return BusService.updateBus(busId, { ...response.data, onRoute: onRoute })
          .then(() => { notifyUpdate(); toast.success(<text>Updated <b>successfully!</b></text>); })
          .catch(error => toast.error(<text><b>Error</b> {error.response.status} occured.</text>));
      })
      .catch(error => toast.error(<text><b>Error</b> {error.response.status} occured.</text>));
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose} 
        sx={{ 
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '21.5em',  // Set your width here
            },
          },
        }}>
        <DialogTitle>{'Record the ' + mode + ' of the bus'}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              busNumber: ''
            }}
            validateOnChange
            validateOnBlur
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const { busNumber } = values;
              updateBusStatus(busNumber, mode === 'departure' ? true : false);
            }}>
            {({ errors, touched, isValid }) => (
              <Form>
                <Box
                  component='form'
                  sx={{ '& .MuiTextField-root': { mt: 1, maxWidth: '18em' } }}
                  noValidate
                  autoComplete='off'>
                  <Typography variant='overline' style={{ lineHeight: '24px' }}>
                    To update status, enter the bus number
                  </Typography>
                  <Field
                    as={TextField}
                    id='busNumber'
                    name='busNumber'
                    label='Bus number'
                    type='text'
                    fullWidth
                    variant='outlined'
                    helperText={(touched.busNumber && errors.busNumber) || ' '}
                    error={touched.busNumber && !!errors.busNumber}/>
                </Box>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    autoFocus
                    type='submit'
                    variant='contained'
                    onClick={handleClose}
                    disabled={!isValid || !(Object.keys(touched).length > 0)}>
                    Update
                  </Button>
                </DialogActions> 
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  ); 
}