import axios from 'axios';

const BASE_URL = process.env.REACT_APP_DRIVERS_REST_API_URL;

class DriverService {
  getAllDrivers() {
    return axios.get(BASE_URL);
  }

  createDriver(driver) {
    return axios.post(BASE_URL, driver);
  }

  updateDriver(id, driver) {
    return axios.put(`${BASE_URL}/${id}`, driver);
  }

  deleteDriver(id) {
    return axios.delete(`${BASE_URL}/${id}`);
  }
}

export default new DriverService();