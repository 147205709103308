import { IoBusSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function MainNavbar() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const NavigationButton = (props) => {
        return (
            <Button
                variant='text'
                style={{ backgroundColor: 'transparent', fontSize: 16 }}
                sx={{ color: '#E0E0E0', '&:hover': { color: '#FFFFFF' }, textTransform: 'none' }}
                {...props}
            />
        );
    };

    return (
        <AppBar position='sticky' sx={{background: 'transparent'}} elevation={0}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box display='flex' alignItems='center' sx={{ fontWeight: 'bold', cursor: 'pointer' }} gap={2} onClick={() => {navigate('/')}}>
                    <IoBusSharp size='36' color='#9063A7' style={{ paddingLeft: 8 }}/>
                    <Typography
                        mx={1}
                        hidden={isSmallScreen ? true : false}
                        variant='h4'
                        fontWeight='bold'>
                        Fleetle
                    </Typography>
                </Box>
                <Box  display='flex' alignItems='center' sx={{ fontWeight: 'bold' }} gap={2}>
                    <NavigationButton onClick={() => { navigate('/dashboard') }}>Dashboard</NavigationButton>
                    <NavigationButton onClick={() => { navigate('/about') }}>About</NavigationButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}