import { Box, Typography } from '@mui/material';


export default function Footer() {
    return (
        <Box
            width='100%'
            display='flex'
            justifyContent='center'>
            <Typography variant='caption' color='lightgray'>Cross-platform Development - 2023</Typography>
        </Box>
    )
}