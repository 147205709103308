import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AboutNavbar from '../Navbars/AboutNavbar';
import Footer from '../Footer/Footer';

export function AboutPage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [pxForMainComponents, setPxForMainComponents] = useState(16);


    useEffect(() => {
        if (isSmallScreen) {
            setPxForMainComponents(4)
        } else if (isMediumScreen) {
            setPxForMainComponents(8)
        } else {
            setPxForMainComponents(16)
        }
    }, [isSmallScreen, isMediumScreen]);

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box pt={8} pb={3} px={pxForMainComponents} sx={{ position: 'fixed', top: 0, width: '100%' }}>
                <AboutNavbar/>
            </Box>
            <Box p={1} px={pxForMainComponents} pt={18} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box display='flex' gap={6} justifyContent='space-between' alignItems='center'>
                    <Box alignItems='center' textAlign='center' display='flex' flexDirection='column'>
                        <Typography py={2} variant='h6' fontWeight='bold' color='#FEBF0F'>About Fleetle</Typography>
                        <Typography pb={2} variant={isSmallScreen ? 'h5' : 'h3'} maxWidth='70%' fontWeight='bold' color='#FFFFFF'>We help organizations track their fleet operations.</Typography>
                        <Box position='relative' maxWidth='65%' sx={{ height: '100%', width: '100%', borderRadius: '10%', overflow: 'hidden'}}>
                            <img src='/mechanic.jpg' style={{ height: '100%', width: '100%', objectFit: 'cover' }}></img>
                            <Box sx={{ position: 'absolute', height: '50%', left: 0, right: 0, bottom: 0, background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,1))' }}/>
                        </Box>
                        <Typography py={2} variant={isSmallScreen ? 'h5' : 'h4'} fontWeight='bold' color='#FFFFFF' pt={4}>Our Philosophy</Typography>
                        <Box color='#FFFFFF' display='flex' flexDirection={isSmallScreen ? 'column' : 'row'} maxWidth={isSmallScreen ? '70%' : '90%'}>
                            <Box p={1} px={isSmallScreen ? 0 : 2}>
                                <img src='/simple.svg'/>
                                <Typography py={1} variant='h5' fontWeight='bold'>Simple</Typography>
                                <Typography>
                                    Fleet management software should reduce complexity, not add to it.
                                </Typography>
                            </Box>
                            <Box p={1} px={isSmallScreen ? 0 : 3}>
                                <img src='/comprehensive.svg'/>
                                <Typography py={1} variant='h5' fontWeight='bold'>Comprehensive</Typography>
                                <Typography>
                                    A central location for all fleet data is key to staying organized.
                                </Typography>
                            </Box>
                            <Box p={1} px={isSmallScreen ? 0 : 3}>
                                <img src='/informative.svg'/>
                                <Typography py={1} variant='h5' fontWeight='bold'>Informative</Typography>
                                <Typography>
                                    Fleet data is useless unless it helps you make better decisions.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box py={4} display='flex' alignItems='center' justifyContent='center'>
                <Footer/>
            </Box>
        </Box>
    )
}