import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ROUTES_REST_API_URL;

class RouteService {
  getAllRoutes() {
    return axios.get(BASE_URL);
  }

  getRouteByRouteNumber(routeNumber) {
    return axios.get(`${BASE_URL}/number/${routeNumber}`);
  }

  createRoute(route) {
    return axios.post(BASE_URL, route);
  }

  updateRoute(id, route) {
    return axios.put(`${BASE_URL}/${id}`, route);
  }

  deleteRoute(id) {
    return axios.delete(`${BASE_URL}/${id}`);
  }
}

export default new RouteService();