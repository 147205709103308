import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import BusService from '../../services/BusService';
import RouteService from '../../services/RouteService';
import DriverService from '../../services/DriverService';


const validationSchema = Yup.object().shape({
  busNumber: Yup.string()
    .required('Bus Number is required')
    .matches(/[A-Z]{2}[0-9]{4}[A-Z]{2}/, 'For example CA3298BT')
    .test(
      'check-for-existance',
      'Bus number must be unique',
      async function(value) {
        try {
          await BusService.getBusByBusNumber(value);
          return false;
        } catch (error) {
          if (error.response.status === 404) {
            return true;
          }
        }
      }
    ),
    routeNumber: Yup.string()
      .required('Route Number is required'),
    driverSurname: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'Surname should contain letters only')
      .required('Driver Surname is required'),
    driverInitials: Yup.string()
      .matches(/^[a-zA-Z]\.[a-zA-Z]\.$/, 'For example: M.I.')
      .required('Driver Initials is required'),
});

export default function NewBusFormDialog({ open, handleClose, notifyUpdate }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  function createBusWithDriverAndRoute(driverSurname, driverInitials, routeNumber, busNumber) {
    return DriverService.createDriver({ surname: driverSurname, initials: driverInitials})
      .then(response => {
        const driverId = response.data.id;
        return RouteService.getRouteByRouteNumber(routeNumber)
          .then(response => {
            const routeId = response.data.id;
            return BusService.createBus({ busNumber: busNumber, driver: { id: driverId }, route: { id: routeId } })
              .then(() => { notifyUpdate(); toast.success(<text>New record added <b>successfully!</b></text>); })
              .catch(error => toast.error(<text><b>Error</b> {error.response.status} occured.</text>));
          })
        .catch(error => {
          if (error.response.status === 404) {
            RouteService.createRoute({ routeNumber: routeNumber })
            .then(response => {
              const routeId = response.data.id;
              return BusService.createBus({ busNumber: busNumber, driver: { id: driverId }, route: { id: routeId } })
                .then(() => { notifyUpdate(); toast.success(<text>New record added <b>successfully!</b></text>); })
                .catch(error => toast.error(<text><b>Error</b> {error.response.status} occured.</text>));
            })
            .catch(error => toast.error(<text><b>Error</b> {error.response.status} occured.</text>));
          } else {
            toast.error(<text><b>Error</b> {error.response.status} occured.</text>);
          }
        });
      })
      .catch(error => toast.error(<text><b>Error</b> {error.response.status} occured.</text>));
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}>
        <DialogTitle>Add new bus</DialogTitle>
        <DialogContent>
          <Formik
            validateOnChange={true}
            initialValues={{
              busNumber: '',
              routeNumber: '',
              driverSurname: '',
              driverInitials: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const { busNumber, routeNumber, driverSurname, driverInitials } = values;
              createBusWithDriverAndRoute(driverSurname, driverInitials, routeNumber, busNumber);
            }}>
            {({ errors, touched, isValid }) => (
              <Form>
                  <Typography variant='overline' style={{ lineHeight: 'inherit' }}>
                    Fill in the form with the data to add a new record
                  </Typography>
                <Box
                  mt={1}
                  component='form'
                  noValidate
                  autoComplete='off'
                  display='flex'
                  flexDirection={isSmallScreen ? 'column' : 'row'}
                  sx={{ '& .MuiTextField-root': { mt: 1} }}
                  gap={!isSmallScreen ? 3 : 0}>
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='caption'>Bus</Typography>
                    <Field
                      as={TextField}
                      id='busNumber'
                      name='busNumber'
                      label='Number'
                      type='text'
                      fullWidth
                      variant='outlined'
                      helperText={(touched.busNumber && errors.busNumber) || ' '}
                      error={touched.busNumber && !!errors.busNumber}/>
                    <Field
                      as={TextField}
                      id='routeNumber'
                      name='routeNumber'
                      label='Route'
                      type='text'
                      fullWidth
                      variant='outlined'
                      helperText={(touched.routeNumber && errors.routeNumber) || ' '}
                      error={touched.routeNumber && !!errors.routeNumber}/>
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='caption'>Driver</Typography>
                    <Field
                      as={TextField}
                      id='driverSurname'
                      name='driverSurname'
                      label='Surname'
                      type='text'
                      fullWidth
                      variant='outlined'
                      helperText={(touched.driverSurname && errors.driverSurname) || ' '}
                      error={touched.driverSurname && !!errors.driverSurname}/>
                    <Field
                      as={TextField}
                      id='driverInitials'
                      name='driverInitials'
                      label='Initials'
                      type='text'
                      fullWidth
                      variant='outlined'
                      helperText={(touched.driverInitials && errors.driverInitials) || ''}
                      error={touched.driverInitials && !!errors.driverInitials}/>
                  </Box>
                </Box>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    autoFocus
                    type='submit'
                    variant='contained' 
                    onClick={handleClose}
                    disabled={!isValid || !(Object.keys(touched).length > 0)}>
                    Add Bus
                  </Button>
                </DialogActions> 
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  ); 
}